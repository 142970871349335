import React from 'react';
import { ResourceCenter } from 'cccisd-laravel-resources';

const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;
const RC_HANDLE = 'Surveys';

const Resources = () => {
    let config = Appdefs.resourceCenters.list.find(rc => rc.id === RC_HANDLE);

    if (!config) {
        return (
            <div className="alert alert-warning">
                <p>No Resource Center found with handle `{RC_HANDLE}`</p>
            </div>
        );
    }

    if (Fortress.auth()) {
        // tweak config to show managed deployments instead of anonymous
        config.tabs[0].tag = 'surveys_managed';
    }

    return <ResourceCenter config={config} />;
};

export default Resources;
