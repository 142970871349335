import React, { useState, useEffect } from 'react';
import axios from 'cccisd-axios';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import notification from 'cccisd-notification';
import { useParams } from 'cccisd-react-router';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

function PlaySurvey() {
    const [isSavingData, setIsSavingData] = useState(false);
    const params = useParams();

    function promptOnUnload(event) {
        if (isSavingData) {
            const message = 'Data is still saving';
            event.returnValue = message;
            return message;
        }
    }

    useEffect(() => {
        if (isSavingData) {
            window.addEventListener('beforeunload', promptOnUnload);
        } else {
            window.removeEventListener('beforeunload', promptOnUnload);
        }

        return () => window.removeEventListener('beforeunload', promptOnUnload);
    }, [isSavingData]);

    if (!params.deploymentHandle) {
        return (
            <div className="alert alert-warning">
                <p>`deploymentHandle` is a required URL param</p>
            </div>
        );
    }

    async function tryRegisterOnComplete(pawnId) {
        // this hook is only for anonymous respondents
        if (Fortress.auth()) {
            setIsSavingData(false);
            return;
        }

        const resp = await axios.post(Boilerplate.route('api.app.rosterAsManaged'), {
            deploymentHandle: params.deploymentHandle,
            pawnId,
        });

        switch (resp.data.status) {
            case 'success':
                if (resp?.data?.data?.publicMessage) {
                    notification({
                        type: 'success',
                        message: resp.data.data.publicMessage,
                        duration: 3000,
                    });
                }
                break;
            case 'error':
                notification({
                    type: 'danger',
                    message: 'Account registration failed:',
                    data: resp.data.errors,
                    duration: 0,
                });
                break;
            default:
                notification({
                    type: 'danger',
                    message: 'Account registration failed',
                    duration: 0,
                });
        }

        setIsSavingData(false);
    }

    return (
        <DeploymentPlayer
            deploymentHandle={params.deploymentHandle}
            onComplete={(lang, flowList, pawnId) => {
                setIsSavingData(true);
                // give 3 seconds for the final survey/assignment progress to finish saving
                // before we try to transfer it from Anon pawn to Managed pawn
                setTimeout(() => tryRegisterOnComplete(pawnId), 2500);
            }}
            flowProps={{
                variables: {
                    isAnonymous: Fortress.auth() ? 2 : 1,
                },
            }}
            {...(Fortress.auth()
                ? { pawnId: Fortress.user.acting.id, pawnHash: Fortress.user.acting.random_hash }
                : {})}
        />
    );
}

export default PlaySurvey;
